<template>
    <div id="customer-dashboard-booking">
        <!-- This only temporary booking dashboard. Soon will merge with existing dashboard-->
        <div v-if="bookingForms && !isLoading" class="cards">
            <div v-for="(booking, ind) in bookingForms" :key="booking.id" class="card custom-card mb-3">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-8 col-12">
                            <div>
                                <span><b>Booking Number : {{ booking.booking_number? booking.booking_number : '-' }}</b></span>
                                <span class="badge badge-pill custom-badge ml-2">{{ getCategoryName(booking.category) }}</span>
                            </div>
                            <div class="mt-1">
                                <span><i class="far fa-calendar"></i> {{ getBookingDate(booking.schedule_start_datetime) }}</span>
                            </div>
                            <div class="mt-1">
                                <span><i class="far fa-clock"></i> {{ getBookingTime(booking.schedule_start_datetime, booking.schedule_end_datetime) }}</span>
                            </div>
                            <div v-if="bookingFormsAssignedProvider[ind]" class="mt-1">
                                <span><i class="fas fa-user-nurse"></i> {{ bookingFormsAssignedProvider[ind].fullname }}</span>
                            </div>
                        </div>
                        <div class="col-sm-4 col-12 d-flex justify-content-end align-items-center">
                            <button v-if="booking.booking_status == 'completed' && !booking.is_feedback_submitted" 
                                class="badge badge-pill custom-badge-large ml-2 btn-color-five" 
                                data-toggle="modal" 
                                data-target="#feedbackModal" 
                                @click="selectedBookingID = booking._id"
                            ><i class="far fa-heart"></i> Give Feedback</button>
                            <button v-else-if="booking.booking_status == 'completed' && booking.is_feedback_submitted" 
                                class="badge badge-pill custom-badge-large ml-2 btn-color-six" 
                            ><i class="far fa-heart"></i> Feedback submitted</button>
                            <span class="badge badge-pill custom-badge-large ml-2" :class="getStatusBtnStyle(booking.booking_status)">{{ getStatusName(booking.booking_status) }}</span>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div v-for="invoice in booking.invoices" :key="invoice.id" class="col-12">
                            <span class="mr-2"><i class="fas fa-file-invoice"></i></span>
                            <router-link :to="`/home-nursing/booking/${booking.id}/invoice/${invoice.id}`" target="_blank">{{invoice.invoice_number}}</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="isLoading" class="text-center">
            <div class="mt-3 mb-3">
                <svg xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" width="64px" height="64px" viewBox="0 0 128 128" xml:space="preserve"><rect x="0" y="0" width="100%" height="100%" fill="#FFFFFF" /><g><circle cx="16" cy="64" r="16" fill="#7cc6d6"/><circle cx="16" cy="64" r="16" fill="#a8d9e4" transform="rotate(45,64,64)"/><circle cx="16" cy="64" r="16" fill="#c8e7ee" transform="rotate(90,64,64)"/><circle cx="16" cy="64" r="16" fill="#e5f4f7" transform="rotate(135,64,64)"/><circle cx="16" cy="64" r="16" fill="#f0f8fa" transform="rotate(180,64,64)"/><circle cx="16" cy="64" r="16" fill="#f0f8fa" transform="rotate(225,64,64)"/><circle cx="16" cy="64" r="16" fill="#f0f8fa" transform="rotate(270,64,64)"/><circle cx="16" cy="64" r="16" fill="#f0f8fa" transform="rotate(315,64,64)"/><animateTransform attributeName="transform" type="rotate" values="0 64 64;315 64 64;270 64 64;225 64 64;180 64 64;135 64 64;90 64 64;45 64 64" calcMode="discrete" dur="1120ms" repeatCount="indefinite"></animateTransform></g></svg>
            </div>
        </div>
         <!-- Modal -->
        <div class="booking-modal modal fade" id="feedbackModal" tabindex="-1" role="dialog"
            aria-labelledby="feedbackModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header text-center d-inline-block">
                        <h5 class="modal-title d-block">
                            Client Satisfication Survey
                        </h5>
                    </div>
                    <div v-if="feedbackItems" class="modal-body">
                        <div class="mx-0 row mb-3">
                            <div class="col-12">
                                <p class="text-center">
                                    Please fill out our brief Customer Satisfaction Survey. All of us at SELCARE Nursing
                                    would like to thank you for using our services. We welcome your feedback regarding the
                                    care that you receive from us. Your honesty helps us work toward better service for
                                    everyone, while ensuring customer satisfaction.
                                </p>
                            </div>
                        </div>
                        <div v-for="(feedbackItem, ind) in feedbackItems" :key="feedbackItem.id" class="mx-0 row mb-5">
                            <div class="offset-2 col-8 text-center">
                                <span class="d-block mb-3 font-weight-500">{{ feedbackItem.name }}</span>
                                <star-rating 
                                    border-color="#d8d8d8" 
                                    :border-width="4"
                                    :rounded-corners="true" 
                                    :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
                                    :show-rating="false" 
                                    :star-size="30" 
                                    :inline="true"
                                    v-model="starRate[ind]"
                                ></star-rating>
                            </div>
                        </div>
                        <div class="mx-0 row mb-2">
                            <div class="col-12 text-center">
                                <label for="" class="font-weight-500 mb-3">Any other comment?</label>
                                <textarea v-model="comment" class="form-control" rows="5"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer text-left d-inline-block pl-4 pr-4">
                        <button class="btn btn-cancel" data-dismiss="modal">Cancel</button>
                        <button class="btn btn-submit float-right" @click="submitFeedback()">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import API from '../../utils/API'
import moment from 'moment'
import StarRating from 'vue-star-rating'

export default {
    components: {
        StarRating
    },
    data(){
        return {
            bookingForms: null,
            bookingFormsAssignedProvider: [],
            isLoading: false,
            selectedBookingID: null,
            feedbackItems: null,
            starRate: [0,0,0,0,0],
            comment: null
        }
    },
    computed: {
        ...mapGetters(['currentCustomer']),
    },
    methods: {
        async getBookingForms(){
            let res = await API.get(`/booking-forms?customer=${this.currentCustomer.user.customer.id}&_sort=createdAt:DESC`)
            this.bookingForms = res.data
        },
        async getAssignedProvider(){
            for (let ind = 0; ind < this.bookingForms.length; ind++) {
                if(this.bookingForms[ind].provider_booking_jobs.length > 0){
                    let assignedJob = this.bookingForms[ind].provider_booking_jobs.find(job => job.type_of_job == 'assigned')
                    if(assignedJob) {
                        let resProvider = await API.get(`/provider-booking-jobs/${assignedJob.id}`)
                        this.bookingFormsAssignedProvider.push(resProvider.data.provider)
                    } else this.bookingFormsAssignedProvider.push(null)
                } else this.bookingFormsAssignedProvider.push(null)
            }
        },
        getCategoryName(_category){
            switch (_category) {
                case 'nurse':
                    return 'Nurse'
                case 'physio':
                    return 'Physio'
                case 'medical_assistant':
                    return 'Medical Assistant'
                case 'care_aide':
                    return 'Care Aide'

                default:
                    break;
            }
        },
        getBookingDate(_startDateTime){
            return moment(_startDateTime).format('dddd, D MMMM YYYY')
        },
        getBookingTime(_startDateTime, _endDateTime){
            return `${moment(_startDateTime).format('LT')} - ${moment(_endDateTime).format('LT')}`
        },
        getStatusBtnStyle(_status){
            switch (_status) {
                case 'completed':
                    return 'btn-color-one'
                case 'pending':
                    return 'btn-color-two'
                case 'validation_error':
                    return 'btn-color-three'
                case 'cancelled':
                    return 'btn-color-three'
            
                default:
                    break;
            }
        },
        getStatusName(_status){
            switch (_status) {
                case 'completed':
                    return 'Completed'
                case 'pending':
                    return 'On Going'
                case 'validation_error':
                    return 'Waiting for Admin'
                case 'cancelled':
                    return 'Cancelled'
            
                default:
                    break;
            }
        },
        async submitFeedback(){
            let data2send = {
                feedback_rates: [],
                customer_comment: this.comment,
                overall_booking_rate: 0.00,
                booking_form: this.selectedBookingID
            }
            this.feedbackItems.forEach((item, ind) => {
                data2send.feedback_rates.push({
                    feedback_id: item.id,
                    name: item.name,
                    // description: item.description,
                    rate: this.starRate[ind]
                })
                data2send.overall_booking_rate += this.starRate[ind]
            })
            data2send.overall_booking_rate = data2send.overall_booking_rate / this.feedbackItems.length
            // console.log('data to submit : ', data2send)
            await API.post('/booking-feedbacks', data2send)
            //dismiss modal
            $('#feedbackModal').modal('toggle');
            //reload data
            this.isLoading = true
            await this.getBookingForms()
            await this.getAssignedProvider()
            this.isLoading = false
        }
    },
    async mounted(){
        this.isLoading = true
        await this.getBookingForms()
        await this.getAssignedProvider()

        let resCustomer = await API.get(`/customers/${this.currentCustomer.user.customer.id}`)
        this.customer = resCustomer.data
        this.isLoading = false

        //load feedbackSettings
        let res = await API.get('/booking-setting')
        this.feedbackItems = res.data.booking_feedbacks
    }
}
</script>

<style scoped>
#customer-dashboard-booking {
    /* height: 500px */
}
#customer-dashboard-booking h1, h2, h3, h4, h5 {
    line-height: 1.4;
    margin: 0 0 20px;
    font-family: "Roboto Slab",serif;
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
}

.chart-box {
    border: 1px solid #9d9d9d;
    padding: 25px 10px;
}
.overview-box {
    border: 1px solid #9d9d9d;
    padding: 10px 10px;
}

.table,
#account-detail {
    font-family: "Karla",arial,sans-serif;
    font-size: 15px;
    line-height: 30px;
    color: #333;
}
#customer-dashboard-booking a {
    color: #333;
}
#customer-dashboard-booking a.text-primary {
    color: #a91a18!important;
}
#customer-dashboard-booking a.text-primary:hover {
    color: #cc2c2a!important;
    text-decoration: underline;
}
#customer-dashboard-booking a:hover {
    color: #000;
    text-decoration: unset;
}

.overview-box i {
    font-size: 12px;
}
.overview-box .box-value {
    font-size: 18px;
    font-weight: bold;
    font-family: "Roboto Slab",serif;
}

.tab-switcher {
    height: 50px;
}
.tab-btn {
    padding: 7px 20px;
    cursor: pointer;
    border-bottom: 3px solid #DADEE3;
    border-radius: 0px;
    color: #A5ABB3;
    font-weight: 500;
}
.tab-btn.is-active {
    border-bottom: 3px solid #A91A18;
    border-radius: 0px;
    color: #A91A18;
    font-weight: 500;
}

.card.custom-card {
    border-radius: 9px;
    border: 1px solid #EBEEF2;
    font-size: 14px;
    /* cursor: pointer; */
}
.card.custom-card .btn {
    color: #ffffff;
    border-radius: 6px;
    font-size: 14px;
    padding: 10px 20px;
}
.card.custom-card a:hover {
    text-decoration: underline!important;;
}

.badge.custom-badge {
    padding: 7px 18px;
    font-weight: 400;
    color: #ff7b00;
    border: 1px solid #ff7b00;
}
.badge.custom-badge-large {
    font-weight: 400;
    color: #ffffff;
    border: unset;
    padding: 10px 25px;
}

.btn-color-one {
    background-color: #7BB348;
    border-color: #7BB348;
}
.btn-color-two {
    background-color: #FF7D65;
    border-color: #FF7D65;
}
.btn-color-three {
    background-color: #AF6297;
    border-color: #AF6297;
}
.btn-color-four {
    background-color: #765F93;
    border-color: #765F93;
}
.btn-color-five {
    color: #E16886!important;
    border: 1px solid #E16886!important;
    background: unset;
}
.btn-color-six {
    color: #7bb348!important;
    border: 1px solid #7bb348!important;
    background: unset;
}

.modal .modal-title {
    text-transform: capitalize;
    font-weight: normal;
    margin: 7px;
    color: #A91A18;
}

.btn.btn-cancel {
    color: #ffffff;
    background-color: #858C94;
    padding: 10px 25px;
}
.btn.btn-submit {
    color: #ffffff;
    background-color: #7CC6D6;
    padding: 10px 80px;
}

@media (min-width: 768px) {
    .chart-box.neg-mr-20,
    .overview-box.neg-mr-20 {
        margin-right: -20px;
    }
}
</style>